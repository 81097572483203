export default {
  app: 'ZenS',
  auth: {
    common: {
      forgotPassword: 'Forgot password?',
      question: 'Do you have an account?',
      question2: 'Do you have an account?',
      chooseTypeTitle: 'OR',
      googleLogin: 'Login with Google',
      facebookLogin: 'Login with Facebook',
      buttonTitle: 'Continue'
    },
    header: {
      home: 'Home',
      price: 'Price',
      contact: 'Contact Us',
      try: 'Try for free'
    },
    login: {
      title: 'Sign in',
      forgot: {
        title: 'Forgot password',
        description: 'Enter your email address and we will send you a OTP to reset your password.'
      },
      reset: {
        title: 'Reset password',
        description: 'Enter your new password.'
      },
      notification: {
        success: 'Login success',
        failure: 'Login failed, email or password is incorrect',
        forgotRequestSuccess: 'Check your email to get a token to reset your password',
        forgotRequestFailure: 'Reset password failed, email is incorrect',
        resetTitle: 'Check your email to get a token to reset your password',
        resetSuccess: 'Reset password success',
        resetFailure: 'Reset password failed, password or OTP is incorrect'
      }
    },
    register: {
      title: 'Sign up',
      policyText1: 'I accept the',
      policyLink: 'Terms of Use',
      policyText2: 'to using this site',
      notification: {
        success: 'Register success',
        failure: 'Register failed, email already exists'
      }
    }
  },
  entities: {
    users: {
      fields: {
        username: {
          label: 'Username',
          placeholder: 'Enter your name'
        },
        email: {
          label: 'Email',
          placeholder: 'Enter your username'
        },
        password: {
          label: 'Password',
          placeholder: 'Enter your password'
        },
        otp: {
          label: 'OTP',
          placeholder: 'Enter your opt'
        }
      }
    },
    videoShare: {
      fields: {
        title: {
          label: 'Title'
        }
      }
    },
    videoBuild: {
      fields: {
        title: {
          label: 'Title'
        },
        description: {
          label: 'Description'
        }
      }
    },
    videoExport: {
      fields: {
        title: {
          label: 'Title'
        },
        description: {
          label: 'Description'
        }
      }
    },
    video: {
      fields: {
        name: {
          defaultValue: 'Untitled Video'
        }
      }
    }
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} is invalid',
      required: '${path} is required',
      oneOf: '${path} must be one of the following values: ${values}',
      notOneOf: '${path} must not be one of the following values: ${values}',
      notType: ({ path, type, value, originalValue }) => {
        return `${path} must be a ${type}`;
      }
    },
    string: {
      length: '${path} must be exactly ${length} characters',
      min: '${path} must be at least ${min} characters',
      max: '${path} must be at most ${max} characters',
      matches: '${path} must match the following: "${regex}"',
      email: '${path} must be a valid email',
      url: '${path} must be a valid URL',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string',
      selected: '${path} must be selected'
    },
    number: {
      min: '${path} must be greater than or equal to ${min}',
      max: '${path} must be less than or equal to ${max}',
      lessThan: '${path} must be less than ${less}',
      moreThan: '${path} must be greater than ${more}',
      notEqual: '${path} must be not equal to ${notEqual}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer'
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}'
    },
    boolean: {},
    object: {
      noUnknown: '${path} field cannot have keys not specified in the object shape'
    },
    array: {
      min: '${path} field must have at least ${min} items',
      max: '${path} field must have less than or equal to ${max} items'
    }
  },
  /* eslint-disable */
  commons: {
    actions: {
      upload: 'Upload',
      search: 'Search',
      build: 'Build',
      share: 'Share',
      create: 'Create',
      delete: 'Delete',
      duplicate: 'Duplicate',
      sure: 'Sure',
      cancel: 'Cancel',
      ok: 'OK',
      reset: 'Reset',
      save: 'Save',
      saved: 'Saved',
      notSaved: 'Unsaved',
      download: 'Download'
    },
    messages: {
      copiedToClipboard: 'Copied to clipboard',
      somethingWrong: 'Something wrong happen!',
      backToHome: 'Back to home',
      uploading: 'Uploading..',
      sureToDelete: 'Are you sure to delete?',
      confirmation: 'Confirmation',
      loading: 'Loading data...',
      noMessage: 'Không có thông báo',
      sureToClone: 'Are you sure to clone this video?'
    }
  },
  editVideo: {
    commons: {
      informVideoSupport: 'Your browser does not support the audio tag.',
      noSceneSelected: 'Chưa có màn hình nào được chọn',
      Delete: 'Delete'
    },
    listSlide: {
      upload: 'Upload Slide',
      newSlide: 'New Slide'
    },
    navbar: {
      preview: 'Preview',
      build: 'Build'
    },
    videoPreview: {
      title: 'Video Preview',
      buildVideo: 'Build Video',
      continueEdit: 'Continue Edit',
      shareVideo: 'Share Video',
      exportToTemplate: 'Export to Template'
    },
    videoBuild: {
      title: 'Build Video',
      summary: 'Video Summary',
      videoTitle: 'Title',
      slideCount: 'Slide count',
      description: 'Description',
      buildTime: 'Build time',
      continueEdit: 'Continue Edit',
      shareVideo: 'Share Video',
      exportToTemplate: 'Export to Template',
      success: 'Request build video is in queue!',
      failure: 'Request build failed! Try again'
    },
    videoShare: {
      title: 'Share Video',
      buildVideo: 'Build Video',
      continueEdit: 'Continue Edit',
      videoTitle: 'Title',
      slideCount: 'Slide count',
      shareVideo: 'Share Video',
      summary: 'Summary',
      exportToTemplate: 'Export to Template',
      shareOption: {
        label: 'Share Option',
        private: 'Private',
        organization: 'Organization',
        public: 'Public',
        viewer: 'Viewer',
        editor: 'Editor'
      },
      shareLink: 'Share link'
    },
    videoExport: {
      buildVideo: 'Build Video',
      title: 'Export Video to Template',
      summary: 'Template Summary',
      videoTitle: 'Title',
      slideCount: 'Slide count',
      description: 'Description',
      exportTime: 'Export time',
      continueEdit: 'Continue Edit',
      shareVideo: 'Share Video',
      exportToTemplate: 'Export to Template'
    },
    sidebar: {
      template: 'Template',
      text: 'Text',
      media: 'Media',
      style: 'Style',
      music: 'Music',
      avatar: 'Avatar',
      help: {
        title: 'Help',
        helpCenter: 'Help Center',
        contactUs: 'Contact Us',
        videoTutorial: 'Video Tutorial'
      }
    },
    sceneView: {
      or: 'OR',
      noScreenChosen: 'No Screen was chosen',
      uploadSpeech: 'Upload speech',
      uploadSuccess: 'Upload success',
      uploadFail: 'Upload fail',
      text: 'Text',
      voiceUpload: 'Voice Upload',
      language: {
        title: 'Language',
        vn: 'Vietnamese',
        en: 'English'
      },
      createSound: 'Create Sound',
      crop: {
        title: 'Crop'
      },
      trim: {
        title: 'Trim'
      },
      selectVoice: {
        voice1: 'north_female_1',
        voice2: 'north_female_2',
        voice3: 'north_male_1',
        voice4: 'south_female_1',
        voice5: 'south_male_1'
      },
      selectSpeed: 'Speed',
      selectVoiceType: 'Voice types',
      createSuccess: 'Create speech successfully'
    },

    mediaTab: {
      favorite: 'Favorite',
      all: 'All',
      image: 'Image',
      video: 'Video',
      library: 'Library',
      myMedia: 'My Media',
      uploaded: 'Uploaded',
      favorites: 'Favorites',
      newFolder: 'Add Folder',
      newMedia: 'Add media',
      deleteFolder: 'Delete Folder',
      deleteFolderSuccess: 'Xoá thư mục thành công',
      deleteFolderFailure: 'Xoá thư mục thất bại',
      createFolderSuccess: 'Tạo thư mục thành công',
      createFolderFailure: 'Tạo thư mục thất bại',
      upload: {
        success: 'Upload complete!',
        failed: 'Upload failed!'
      }
    },
    musicTab: {
      library: 'Library',
      myMusic: 'My Music',
      uploaded: 'Uploaded',
      favorites: 'Favorites',
      filter: 'Filter by categories',
      upload: {
        success: 'Upload complete!',
        failed: 'Upload failed!'
      },
      addSoundToVideo: {
        failed: 'Failed to add sound to video!',
        success: 'Success to add sound to video!',
        removeBackgroundSound: 'Remove background sound from video'
      },
      deleteSound: {
        failed: 'Fail to delete sound!',
        success: 'Delete sound s!'
      }
    },
    templateTab: {
      library: 'Library',
      myTemplate: 'My Template',
      created: 'Created',
      favorites: 'Favorites',
      slides: 'Slides',
      useIt: 'Use It',
      applyForAll: 'Apply for all'
    },
    templateDetails: {
      confirmApply: 'This template will replace the current scene?',
      confirmApplyForAll: 'Replace all template?'
    },
    templateCard: {
      slides: 'Slides'
    },
    toolbarVolumePopover: {
      turnOn: 'Turn on sound',
      voiceVolume: 'Voice volume',
      musicVolume: 'Music volume',
      applyForAll: 'Apply for all scenes',
      applySuccess: 'Apply success!',
      applyFailed: 'Apply failed!'
    },
    toolbarFilterPopover: {
      filter: 'Color filter',
      applyForAll: 'Apply for all scenes',
      defaultName: 'Filter name'
    },
    styleTab: {
      fontDefaultName: 'Font name',
      fontColor: 'Font color',
      backgroundColor: 'Background color',
      font: 'Font',
      transitionEffect: 'Transition effects',
      transitionEffectDefaultName: 'Effect name',
      logo: 'Logo',
      position: 'Position',
      size: 'Size',
      uploadLogoPNGOnly: 'Only PNG file is allowed',
      fade: 'Fade',
      fadeblack: 'Fade black',
      fadewhite: 'Fade white',
      wipeleft: 'Wipe left',
      wiperight: 'Wipe right',
      slideleft: 'Slide left',
      slideright: 'Slide right'
    },
    textTab: {
      content: 'Nội dung',
      textEffect: 'Hiệu ứng chữ',
      slideIn: 'Trượt vào',
      scale: 'Thu phóng chậm',
      scaleFast: 'Thu phóng nhanh',
      reunion: 'Tái hợp',
      styleSlideIn: 'Trượt vào với hiệu ứng'
    },
    popupBuildVideo: {
      title: 'Video is built',
      estimateTime: 'Estimated time',
      time: '{{ number}} minutes',
      remained: 'Remained time',
      remainedTime: '{{ number}} minutes',
      note: 'You can close this popup and continue edit another video. When video is built successfully, you will be received a notification to email',
      close: 'Close'
    },
    avatarTab: {
      title: 'Select avatar',
      format: 'Format',
      style: 'Style',
      position: 'Position',
      size: 'Size',
      backgroundColor: 'Background',
      avatarName: 'Avatar Name',
      circleAndFull: 'Circle and full',
      position: 'Left/center/right',
      big: 'Big'
    }
  },
  homeVideo: {
    common: {
      owner: 'Owner:',
      action: {
        restore: 'Restore Video',
        add: 'New Lecture',
        import: 'Import Slide',
        preview: 'Preview',
        edit: 'Edit Video',
        delete: 'Delete Video',
        share: 'Share Video',
        create: 'Create Video',
        duplicate: 'Duplicate Video',
        createTemplate: 'Create Template',
        templateName: ' Template Name',
        slides: '{{ number}} slides',
        videoName: 'Video name',
        deleteSuccess: 'Delete Successfully!',
        deleteFailed: 'Delete Failed!',
        restoreSuccess: 'Restore Successfully!',
        changeTemplateName: 'Change your template name',
        create: 'Create',
        close: 'Close',
        cloneSuccess: 'Clone Successfully!'
      },
      templates: 'templates',
      title: {
        allTemplate: 'All Templates'
      }
    },

    modal: {
      addModal: {
        headerTitle: 'Create Lecture from file',
        tabs: {
          computer: {
            label: 'From your device',
            title: 'Upload file from your device',
            content: 'Drag & drop ppt, pptx, pdf file from your computer',
            or: 'OR',
            link: '[Click here to upload]'
          },
          link: {
            label: 'Link file',
            title: '(google slide, pdf, ppt, pptx)',
            placeholder: 'Input file url'
          },
          button: {
            chooseAgain: 'Choose again',
            create: 'Create'
          }
        }
      },
      previewModal: {
        button: 'Create video from thís template'
      },
      deleteModal: {
        question: 'Are you sure you want to delete this video?',
        forceDelete: 'Do you want to delete this video forever?',
        button: {
          cancel: 'Cancel',
          delete: 'Delete'
        }
      }
    },
    menu: {
      title: 'Start a new lecture from the templates',
      subTitle: {
        add: 'Blank',
        import: 'Import',
        preview: 'Preview'
      }
    },
    homeTemplate: {
      title: {
        lecture: 'Lecture',
        shareWithMe: 'Share with me',
        bin: 'Bin',
        search: 'Search'
      },
      privacy: {
        private: 'Private',
        public: 'Public',
        organization: 'Org'
      },
      status: {
        draft: 'Draft',
        ready: 'Ready'
      },
      action: {
        duplicate: 'Duplicate Video',
        share: 'Share Video',
        create: 'Create Template'
      }
    },
    allTemplate: {
      title: {
        library: 'Library',
        myTemplates: 'My Templates'
      }
    }
  },
  accountPopover: {
    profile: 'Profile',
    logout: 'Logout'
  }
};
