import { createTypes } from 'reduxsauce';
import { ENDPOINTS } from './config';
import axiosClient2 from '../../utils/axiosClient2';

export const HistoryTypes = createTypes(`
  HISTORY_FETCH_SUCCESS
  HISTORY_FETCH_FAILURE
`);

export const fetchHistorySuccess = (response) => ({ type: HistoryTypes.HISTORY_FETCH_SUCCESS, data: response });
export const fetchHistoryFailure = (error) => ({ type: HistoryTypes.HISTORY_FETCH_FAILURE, error });
export const fetchHistory = (id, params) => async (dispatch) => {
  try {
    const response = await axiosClient2.get(ENDPOINTS.histories(id), { params });
    dispatch(fetchHistorySuccess({ ...response.data, historyType: params.type }));
  } catch (error) {
    dispatch(fetchHistoryFailure(error));
  }
};
