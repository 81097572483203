import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { App } from './App';
import { configureStore } from './configureStore';
import reportWebVitals from './reportWebVitals';
import history from './utils/history';
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const persistor = persistStore(store);

function LoadingOverlay() {
  return <div>Loading</div>;
}

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'right'
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={<LoadingOverlay />}>
      <BrowserRouter>
        <SnackbarProvider anchorOrigin={anchorOrigin} maxSnack={3}>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </SnackbarProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
