import { createReducer } from 'reduxsauce';
import { OrderTypes } from './actions';

const requestState = {
  orders: [],
  order: null,
  total: 0,
  orderUrl: null,
  loading: true,
  error: null
};

const INITIAL_STATE = {
  ...requestState
};

const addOrderSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null
});

const addOrderFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  error: actions.error
});

const fetchOrderDetailSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  order: actions.data,
  error: null
});

const fetchOrderDetailFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  error: actions.error
});

const fetchOrderPaymentUrlSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  orderUrl: actions.data,
  error: null
});

const fetchOrderPaymentUrlFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  error: actions.error
});

const fetchOrderSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  orders: actions.data?.result,
  total: actions.data?.total,
  loading: false,
  error: null
});

const fetchOrderFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  error: actions.error
});

const paidOrderSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null
});

const paidOrderFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  error: actions.error
});

export const HANDLERS = {
  [OrderTypes.ADD_ORDER_FAILURE]: addOrderFailure,
  [OrderTypes.ADD_ORDER_SUCCESS]: addOrderSuccess,
  [OrderTypes.FETCH_ORDER_DETAIL_SUCCESS]: fetchOrderDetailSuccess,
  [OrderTypes.FETCH_ORDER_DETAIL_FAILURE]: fetchOrderDetailFailure,
  [OrderTypes.FETCH_ORDER_PAYMENT_URL_SUCCESS]: fetchOrderPaymentUrlSuccess,
  [OrderTypes.FETCH_ORDER_PAYMENT_URL_FAILURE]: fetchOrderPaymentUrlFailure,
  [OrderTypes.FETCH_ORDER_SUCCESS]: fetchOrderSuccess,
  [OrderTypes.FETCH_ORDER_FAILURE]: fetchOrderFailure,
  [OrderTypes.PAID_ORDER_SUCCESS]: paidOrderSuccess,
  [OrderTypes.PAID_ORDER_FAILURE]: paidOrderFailure
};

export const orderReducer = createReducer(INITIAL_STATE, HANDLERS);
