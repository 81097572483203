import { Container, CssBaseline } from '@mui/material';
import { Spin } from 'antd';
import { ThemeProvider } from '@mui/material/styles';
import React, { Suspense } from 'react';
import { useRoutes } from 'react-router';
import theme from 'theme';
import './i18n';
import './pusher';
import routes from './routes';
import './App.css';

export function App() {
  const routing = useRoutes(routes);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth={false} disableGutters sx={{ height: '100%' }}>
        <CssBaseline />
        <Suspense
          fallback={
            <div
              className="d-flex flex-direct-column justify-content-center align-items-center"
              style={{ height: '100%' }}
            >
              <Spin />
            </div>
          }
        >
          {routing}
        </Suspense>
      </Container>
    </ThemeProvider>
  );
}
