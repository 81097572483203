import { createReducer } from 'reduxsauce';
import { TemplatesListTypes } from './actions';

const requestState = {
  error: null,
  templates: [],
  loading: false
};
export const INITIAL_STATE = {
  ...requestState
};

const getMyTemplates = (state = INITIAL_STATE, actions) => ({
  ...state,
  templates: actions.templates
});

const searchTemplateRequest = (state = INITIAL_STATE, actions) => ({
  ...state,
  loading: true,
  searchTerm: actions.searchTerm
});

const searchTemplateSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  templateLibrary: actions.templates,
  loading: false
});
const searchTemplateFailure = (state = INITIAL_STATE, error) => ({
  ...state,
  error,
  loading: false
});
const getTemplateLibrary = (state = INITIAL_STATE, actions) => ({
  ...state,
  loading: true,
  templateLibrary: actions.data
});

const deleteTemplateRequest = (state = INITIAL_STATE, actions) => ({
  ...state,
  loading: true,
  templates: actions.newListTemplate
});
const deleteTemplateSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false
});
const deleteTemplateFailure = (state = INITIAL_STATE, error, actions) => ({
  ...state,
  templates: actions.listTemplate,
  error
});
export const HANDLERS = {
  [TemplatesListTypes.GET_MY_TEMPLATES]: getMyTemplates,
  [TemplatesListTypes.GET_TEMPLATE_SEARCH_REQUEST]: searchTemplateRequest,
  [TemplatesListTypes.GET_TEMPLATE_SEARCH_SUCCESS]: searchTemplateSuccess,
  [TemplatesListTypes.GET_TEMPLATE_SEARCH_FAILURE]: searchTemplateFailure,

  [TemplatesListTypes.GET_TEMPLATE_LIBRARY]: getTemplateLibrary,

  [TemplatesListTypes.DELETE_TEMPLATE_REQUEST]: deleteTemplateRequest,
  [TemplatesListTypes.DELETE_TEMPLATE_SUCCESS]: deleteTemplateSuccess,
  [TemplatesListTypes.DELETE_TEMPLATE_FAILURE]: deleteTemplateFailure
};

export const templatesListReducer = createReducer(INITIAL_STATE, HANDLERS);
