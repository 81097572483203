import { createTypes } from 'reduxsauce';
import axiosClient from 'utils/axiosClient';
import { ENDPOINTS } from './config';

export const RegisterTypes = createTypes(`
  REGISTER_SUCCESS
  REGISTER_FAILURE
`);

// Login action
export const registerSuccess = (user) => ({ type: RegisterTypes.REGISTER_SUCCESS, user });

export const registerFailure = (error) => ({
  error,
  type: RegisterTypes.REGISTER_FAILURE
});

export const registerRequest =
  ({ name, email, password }) =>
  () =>
    axiosClient.post(ENDPOINTS.register, { name, email, password });
