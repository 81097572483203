import _get from 'lodash/get';
import { createTypes } from 'reduxsauce';
import axiosClient from 'utils/axiosClient';
import axiosClient2 from 'utils/axiosClient2';
import { ENDPOINTS } from './config';

export const GetVideoTypes = createTypes(`
  GET_VIDEOS
  
  GET_VIDEOS_BY_USER_REQUEST
  GET_VIDEOS_BY_USER_SUCCESS
  GET_VIDEOS_BY_USER_FAILURE

  GET_VIDEOS_SEARCH_REQUEST
  GET_VIDEOS_SEARCH_SUCCESS
  GET_VIDEOS_SEARCH_FAILURE


  DELETE_VIDEO_REQUEST
  DELETE_VIDEO_SUCCESS
  DELETE_VIDEO_FAILURE
  EDIT_TITLE_VIDEO_REQUEST
  EDIT_TITLE_VIDEO_SUCCESS
  EDIT_TITLE_VIDEO_FAILURE

  FORCE_DELETE_VIDEO_REQUEST
  FORCE_DELETE_VIDEO_SUCCESS
  FORCE_DELETE_VIDEO_FAILURE

  RESTORE_VIDEO_REQUEST
  RESTORE_VIDEO_SUCCESS
  RESTORE_VIDEO_FAILURE

  CREATE_NEW_VIDEO_FROM_SLIDE_REQUEST
  CREATE_NEW_VIDEO_FROM_SLIDE_SUCCESS
  CREATE_NEW_VIDEO_FROM_SLIDE_FAILURE

  CREATE_NEW_BLANK_VIDEO_REQUEST
  CREATE_NEW_BLANK_VIDEO_SUCCESS
  CREATE_NEW_BLANK_VIDEO_FAILURE

  HOME_VIDEO_BUILD_SUCCESS
  HOME_VIDEO_UPDATE_VIDEO

  GET_VIDEOS_ORG

  CLONE_VIDEO_REQUEST
  CLONE_VIDEO_SUCCESS
  CLONE_VIDEO_FAILURE

`);
export const getMyVideos = (videos) => ({ type: GetVideoTypes.GET_VIDEOS, videos });

export const getVideosByUserSuccess = (response) => ({
  type: GetVideoTypes.GET_VIDEOS_BY_USER_SUCCESS,
  data: response
});
export const getVideosByUserFailure = (error) => ({ type: GetVideoTypes.GET_VIDEOS_BY_USER_FAILURE, error });
export const getVideosByUserRequest = (organizationId, params) => async (dispatch) => {
  try {
    dispatch({ type: GetVideoTypes.GET_VIDEOS_BY_USER_REQUEST });
    const response = await axiosClient2.get(ENDPOINTS.listVideoByUser(organizationId), { params });
    dispatch(getVideosByUserSuccess(response.data));
  } catch (e) {
    dispatch(getVideosByUserFailure(e));
  }
};

export const searchSuccess = (videos) => ({ type: GetVideoTypes.GET_VIDEOS_SEARCH_SUCCESS, videos });
export const searchFailure = (error) => ({ type: GetVideoTypes.GET_VIDEOS_SEARCH_FAILURE, error });

export const searchRequest = (searchTerm) => async (dispatch) => {
  try {
    dispatch({ type: GetVideoTypes.GET_VIDEOS_SEARCH_REQUEST, searchTerm });
    const response = await axiosClient.get(ENDPOINTS.search(searchTerm));
    dispatch(searchSuccess(response.videos.data));
  } catch (e) {
    dispatch(searchFailure(e));
  }
};

export const deleteVideoSuccess = () => ({ type: GetVideoTypes.DELETE_VIDEO_SUCCESS });
export const deleteVideoFailure = (error, videos) => ({ type: GetVideoTypes.DELETE_VIDEO_FAILURE, error, videos });

export const deleteVideoRequest = (id) => async (dispatch, getState) => {
  const listVideo = _get(getState(), 'homeVideos.videos', []);
  const newListVideo = listVideo.filter((video) => video._id !== id);
  try {
    dispatch({ type: GetVideoTypes.DELETE_VIDEO_REQUEST, newListVideo });
    await axiosClient.delete(ENDPOINTS.delete(id));
    dispatch(deleteVideoSuccess());
  } catch (e) {
    dispatch(deleteVideoFailure(e, listVideo));
  }
};
export const forceDeleteVideoSuccess = (videos) => ({ type: GetVideoTypes.FORCE_DELETE_VIDEO_SUCCESS, videos });
export const forceDeleteVideoFailure = (error, videos) => ({
  type: GetVideoTypes.FORCE_DELETE_VIDEO_FAILURE,
  error,
  videos
});

export const forceDeleteVideoRequest = (id, videosInRecycleBin, setVideosInRecycleBin) => async (dispatch) => {
  const newListVideo = videosInRecycleBin.filter((video) => video._id !== id);
  try {
    dispatch({ type: GetVideoTypes.FORCE_DELETE_VIDEO_REQUEST, newListVideo });
    await axiosClient.delete(ENDPOINTS.forceDelete(id));
    setVideosInRecycleBin(newListVideo);
    dispatch(forceDeleteVideoSuccess());
  } catch (e) {
    dispatch(forceDeleteVideoFailure(e, videosInRecycleBin));
  }
};

export const editTitleVideoSuccess = (videos) => ({ type: GetVideoTypes.EDIT_TITLE_VIDEO_SUCCESS, videos });
export const editTitleVideoFailure = (error, videos) => ({
  type: GetVideoTypes.EDIT_TITLE_VIDEO_FAILURE,
  error,
  videos
});

export const editTitleVideoRequest = (id, name) => async (dispatch, getState) => {
  const listVideo = _get(getState(), 'homeVideos.videos', []);
  const newListVideo = listVideo.map((video) => (video._id === id ? { ...video, name } : video));
  try {
    dispatch({ type: GetVideoTypes.EDIT_TITLE_VIDEO_REQUEST, videos: newListVideo });
    const rs = await axiosClient.patch(ENDPOINTS.edit(id), { name });
    const rsListVideo = listVideo.map((video) => (video._id === id ? { ...rs.video } : video));
    dispatch(editTitleVideoSuccess(rsListVideo));
  } catch (e) {
    dispatch(editTitleVideoFailure(e.listVideo));
  }
};

export const restoreVideoSuccess = (videos) => ({ type: GetVideoTypes.RESTORE_VIDEO_SUCCESS, videos });
export const restoreVideoFailure = (error) => ({ type: GetVideoTypes.RESTORE_VIDEO_FAILURE, error });

export const restoreVideoRequest = (id) => async (dispatch) => {
  try {
    dispatch({ type: GetVideoTypes.RESTORE_VIDEO_REQUEST, id });
    await axiosClient.post(ENDPOINTS.restore(id));
    dispatch(restoreVideoSuccess());
  } catch (e) {
    dispatch(restoreVideoFailure(e));
  }
};

export const createNewBlankVideoSuccess = (videos) => ({
  type: GetVideoTypes.CREATE_NEW_BLANK_VIDEO_SUCCESS,
  videos
});
export const createNewBlankVideoFailure = (error) => ({
  type: GetVideoTypes.CREATE_NEW_BLANK_VIDEO_FAILURE,
  error
});
export const createNewBlankVideoRequest = () => async (dispatch, getState) => {
  const listVideo = _get(getState(), 'homeVideos.videos', []);
  try {
    dispatch({ type: GetVideoTypes.CREATE_NEW_BLANK_VIDEO_REQUEST });
    const response = await axiosClient.post(ENDPOINTS.createVideo);
    const newListVideo = [...listVideo, response.video];
    dispatch(createNewBlankVideoSuccess(newListVideo));
    return response;
  } catch (e) {
    dispatch(createNewBlankVideoFailure(e.listVideo));
    return e;
  }
};

export const buildSuccess = (video) => ({ type: GetVideoTypes.HOME_VIDEO_BUILD_SUCCESS, video });

export const updateVideo = (videoID, data) => ({ type: GetVideoTypes.HOME_VIDEO_UPDATE_VIDEO, videoID, data });

export const getVideosOrg = (videos) => ({ type: GetVideoTypes.GET_VIDEOS_ORG, videos });

export const cloneVideoRequest = (id) => async (dispatch, getState) => {
  const listVideo = _get(getState(), 'homeVideos.videos', []);
  try {
    dispatch({ type: GetVideoTypes.CLONE_VIDEO_REQUEST });
    const response = await axiosClient.post(ENDPOINTS.clone(id));
    const newListVideo = [response.video, ...listVideo];
    dispatch(cloneVideoSuccess(newListVideo));
    return response;
  } catch (e) {
    dispatch(cloneVideoFailure(listVideo));
    return e;
  }
};

export const cloneVideoSuccess = (videos) => ({ type: GetVideoTypes.CLONE_VIDEO_SUCCESS, videos });
export const cloneVideoFailure = (error, videos) => ({ type: GetVideoTypes.CLONE_VIDEO_FAILURE, error, videos });
