import _get from 'lodash/get';
import { createTypes } from 'reduxsauce';
import axiosClient from 'utils/axiosClient';
import { ENDPOINTS } from '../../Menu/config';

export const CreateVideoTypes = createTypes(`
  
  CREATE_NEW_VIDEO_FROM_SLIDE_REQUEST
  CREATE_NEW_VIDEO_FROM_SLIDE_SUCCESS
  CREATE_NEW_VIDEO_FROM_SLIDE_FAILURE


`);
export const createNewVideoFromSlideSuccess = (videos) => ({
  type: CreateVideoTypes.CREATE_NEW_VIDEO_FROM_SLIDE_SUCCESS,
  videos
});
export const createNewVideoFromSlideFailure = (error) => ({
  type: CreateVideoTypes.CREATE_NEW_VIDEO_FROM_SLIDE_FAILURE,
  error
});
export const createNewVideoFromSlideRequest = (file, config) => async (dispatch, getState) => {
  const formData = new FormData();
  formData.append('file', file);

  const listVideo = _get(getState(), 'homeVideos.videos', []);
  try {
    dispatch({ type: CreateVideoTypes.CREATE_NEW_VIDEO_FROM_SLIDE_REQUEST });
    const response = await axiosClient.post(ENDPOINTS.createVideo, formData, config);
    const newListVideo = [...listVideo, response.video];
    dispatch(createNewVideoFromSlideSuccess(newListVideo));
    return response;
  } catch (e) {
    dispatch(createNewVideoFromSlideFailure(e.listVideo));
    return e;
  }
};
