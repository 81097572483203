export const TAB = {
  TRANSACTION: 'TRANSACTION',
  VIDEO: 'VIDEO'
};

export const HISTORY_TYPE = {
  ORDER: 'order',
  BUILD: 'build'
};

export const DEFAULT_LIST_HISTORY_PARAMS = {
  is_paginate: 1,
  page: 1,
  items_per_page: 10
};
