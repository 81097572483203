import axios from 'axios';

const axiosConfig = {
  baseURL: process.env.REACT_APP_LMS_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
};

const axiosClient = axios.create(axiosConfig);

const storeToken = (token) => {
  localStorage.setItem('access_token', token);
};

const destroyToken = () => {
  localStorage.removeItem('access_token');
};

let refreshTokenRequesting = null;

const refreshToken = async () => {
  const res = await axiosClient.get('auth/refresh');
  storeToken(res.access_token);
};

axiosClient.interceptors.request.use(
  (req) => {
    req.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
    return req;
  },
  (err) => Promise.reject(err)
);

axiosClient.interceptors.response.use(
  (res) => res,
  async (err) => {
    const { status, data, config } = err.response;

    if (status === 401 && data.message === 'Token is Expired') {
      try {
        refreshTokenRequesting = refreshTokenRequesting || refreshToken();
        await refreshTokenRequesting;
        config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
        refreshTokenRequesting = null;
        return axiosClient.request(config);
      } catch {
        destroyToken();
        window.location = 'login';
      }
    }

    return Promise.reject(err.response?.data);
  }
);

export default axiosClient;
