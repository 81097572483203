import i18n from 'i18next';
import _get from 'lodash/get';
import { initReactI18next } from 'react-i18next';
import { setLocale } from 'yup';
import en from './translations/en';
import vi from './translations/vi';

export const resources = {
  en: {
    translation: en
  },
  vi: {
    translation: vi
  }
};

export default i18n.use(initReactI18next).init({
  resources,
  lng: 'vi',
  fallbackLng: 'vi',
  interpolation: {
    escapeValue: false
  }
});

export { i18n };

setLocale(_get(resources.vi, 'translation.validation'));
