import { createReducer } from 'reduxsauce';
import { PackageTypes } from './actions';

const requestState = {
  packages: [],
  loading: true,
  error: null
};

const INITIAL_STATE = {
  ...requestState
};

const fetchSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  packages: actions.data.result,
  loading: false,
  error: null
});

const fetchFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  error: actions.error
});

export const HANDLERS = {
  [PackageTypes.PACKAGES_FETCH_FAILURE]: fetchFailure,
  [PackageTypes.PACKAGES_FETCH_SUCCESS]: fetchSuccess
};

export const packageReducer = createReducer(INITIAL_STATE, HANDLERS);
