import { createReducer } from 'reduxsauce';
import { CreateVideoTypes } from './actions';

const requestState = {
  error: null,
  loading: false,
  createStatusFromSlide: { loading: false, error: null }
};

export const INITIAL_STATE = {
  ...requestState
};
const createNewVideoFromSlideRequest = (state = INITIAL_STATE) => ({
  ...state,
  createStatusFromSlide: { loading: true }
});
const createNewVideoFromSlideSuccess = (state = INITIAL_STATE) => ({
  ...state,
  createStatusFromSlide: { loading: false }
});
const createNewVideoFromSlideFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  createStatusFromSlide: { loading: false, error: actions.error }
});
export const HANDLERS = {
  [CreateVideoTypes.CREATE_NEW_VIDEO_FROM_SLIDE_REQUEST]: createNewVideoFromSlideRequest,
  [CreateVideoTypes.CREATE_NEW_VIDEO_FROM_SLIDE_SUCCESS]: createNewVideoFromSlideSuccess,
  [CreateVideoTypes.CREATE_NEW_VIDEO_FROM_SLIDE_FAILURE]: createNewVideoFromSlideFailure
};

export const createNewVideoReducer = createReducer(INITIAL_STATE, HANDLERS);
