import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { createReducer } from 'reduxsauce';
import { AppTypes } from './actions';

const requestState = {
  error: null,
  value: false,
  loading: false
};

export const INITIAL_STATE = {
  user: null,
  language: {
    ...requestState,
    value: 'en'
  },
  modal: {
    name: '',
    show: false
  }
};

const languageRequest = (state = INITIAL_STATE) => ({
  ...state,
  language: {
    ...requestState,
    loading: true
  }
});

const languageSuccess = (state = INITIAL_STATE, { language }) => ({
  ...state,
  language: {
    ...requestState,
    value: language,
    loading: false
  }
});

const languageFailure = (state = INITIAL_STATE, { error }) => ({
  ...state,
  language: {
    ...requestState,
    error,
    loading: false
  }
});

const login = (state = INITIAL_STATE, { user }) => ({
  ...state,
  user
});

const logout = (state = INITIAL_STATE) => ({
  ...state,
  user: null
});

const modalShow = (state = INITIAL_STATE, action) => ({
  ...state,
  modal: {
    name: action.modal,
    show: true,
    data: action.data
  }
});

const modalClose = (state = INITIAL_STATE, action) => ({
  ...state,
  modal: {
    name: action.modal,
    show: false
  }
});

const modalRefresh = (state = INITIAL_STATE) => ({
  ...state,
  modal: {
    name: '',
    show: false
  }
});

const updateUser = (state = INITIAL_STATE, { user }) => ({
  ...state,
  user
});

export const HANDLERS = {
  [AppTypes.LANGUAGE_REQUEST]: languageRequest,
  [AppTypes.LANGUAGE_SUCCESS]: languageSuccess,
  [AppTypes.LANGUAGE_FAILURE]: languageFailure,
  [AppTypes.LOGIN]: login,
  [AppTypes.LOGOUT]: logout,
  [AppTypes.MODAL_SHOW]: modalShow,
  [AppTypes.MODAL_CLOSE]: modalClose,
  [AppTypes.MODAL_REFRESH]: modalRefresh,
  [AppTypes.UPDATE_USER]: updateUser
};

const appReducer = createReducer(INITIAL_STATE, HANDLERS);

const persistConfig = {
  keyPrefix: 'videoEditor-',
  key: 'app',
  storage
};

export default persistReducer(persistConfig, appReducer);
