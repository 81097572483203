export const ENDPOINTS = {
  listVideo: (page) => `/videos?page=${page}`,
  listTemplates: '/templates/all',
  videosInRecycleBin: (page) => `/videos/recycle-bin?page=${page}`,
  createVideo: '/videos',
  search: (searchTerm) => `/videos?name=${searchTerm}`,
  delete: (id) => `/videos/${id}`,
  forceDelete: (id) => `/videos/${id}/force-delete`,
  edit: (id) => `/videos/${id}`,
  restore: (id) => `/videos/${id}/restore`,
  listVideoByUser: (id) => `organization/${id}/video`,
  listVideoByOrganization: (page) => `videos/organization/?page=${page}`,
  clone: (id) => `/videos/${id}/clone`
};
