import { createReducer } from 'reduxsauce';
import { GroupTypes } from './actions';

const requestState = {
  group: null,
  groups: [],
  groupsWithoutPaginate: [],
  groupsAll: [],
  member: null,
  members: [],
  total: 0,
  loading: true,
  error: null,
  timeTrigger: 0,
  addGroupError: null,
  resetAddGroupError: null,
  addedMember: null,
  addedMemberLoading: true,
  inviteMemberLoading: false,
  invite: null,
  membersInvite: []
};

const INITIAL_STATE = {
  ...requestState
};

const fetchSuccessWithoutPaginate = (state = INITIAL_STATE, actions) => ({
  ...state,
  groupsWithoutPaginate: actions.data.result,
  error: null
});

const fetchFailureWithoutPaginate = (state = INITIAL_STATE, actions) => ({
  ...state,
  error: actions.error
});

const fetchSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  groups: actions.data.result,
  total: actions.data.total,
  loading: false,
  error: null
});

const fetchFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  error: actions.error
});

const fetchDetailSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  group: actions.data,
  error: null
});

const fetchDetailFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  error: actions.error
});

const fetchDetailMemberSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  member: actions.data,
  error: null
});

const fetchDetailMemberFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  error: actions.error
});

const fetchGroupMembersSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  members: actions.data,
  error: null
});

const fetchGroupMembersFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  error: actions.error
});

const fetchGroupMembersInviteSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  membersInvite: actions.data,
  error: null
});

const fetchGroupMembersInviteFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  error: actions.error
});

const addGroupMemberSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  addedMember: actions.data,
  addedMemberLoading: false,
  error: null
});

const addGroupMemberFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  error: actions.error
});

const addGroupSuccess = (state = INITIAL_STATE) => ({
  ...state,
  addGroupError: { error: true }
});

const addGroupFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  addGroupError: actions.error
});

const resetAddGroupFailure = (state = INITIAL_STATE) => ({
  ...state,
  addGroupError: null,
  resetAddGroupError: true
});

const leaveGroupSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null
});

const leaveGroupFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  error: actions.error
});

const removeMemberSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null
});

const removeMemberFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  error: actions.error
});

const activeGroupSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null
});

const activeGroupFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  error: actions.error
});

const entrustVideoSuccess = (state = INITIAL_STATE) => ({
  ...state,
  timeTrigger: new Date().getTime(),
  error: null
});

const entrustVideoFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  error: actions.error
});

const fetchGroupAllSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  groupsAll: actions.data.result,
  error: null
});

const fetchGroupAllFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  error: actions.error
});

const inviteMemberRequest = (state = INITIAL_STATE) => ({
  ...state,
  inviteMemberLoading: true,
  error: null
});

const inviteMemberSuccess = (state = INITIAL_STATE) => ({
  ...state,
  inviteMemberLoading: false,
  error: null
});

const inviteMemberFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  inviteMemberLoading: false,
  error: actions.error
});

const inviteDetailSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  invite: actions.data,
  error: null
});

const inviteDetailFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  error: actions.error
});

export const HANDLERS = {
  [GroupTypes.GROUPS_FETCH_FAILURE]: fetchFailure,
  [GroupTypes.GROUPS_FETCH_SUCCESS]: fetchSuccess,
  [GroupTypes.GROUP_DETAIL_FETCH_SUCCESS]: fetchDetailSuccess,
  [GroupTypes.GROUP_DETAIL_FETCH_FAILURE]: fetchDetailFailure,
  [GroupTypes.GROUP_DETAIL_MEMBER_FETCH_SUCCESS]: fetchDetailMemberSuccess,
  [GroupTypes.GROUP_DETAIL_MEMBER_FETCH_FAILURE]: fetchDetailMemberFailure,
  [GroupTypes.GROUP_MEMBERS_FETCH_SUCCESS]: fetchGroupMembersSuccess,
  [GroupTypes.GROUP_MEMBERS_FETCH_FAILURE]: fetchGroupMembersFailure,
  [GroupTypes.ADD_GROUP_MEMBER_SUCCESS]: addGroupMemberSuccess,
  [GroupTypes.ADD_GROUP_MEMBER_FAILURE]: addGroupMemberFailure,
  [GroupTypes.RESET_ADD_GROUP_FAILURE]: resetAddGroupFailure,
  [GroupTypes.ADD_GROUP_SUCCESS]: addGroupSuccess,
  [GroupTypes.ADD_GROUP_FAILURE]: addGroupFailure,
  [GroupTypes.LEAVE_GROUP_SUCCESS]: leaveGroupSuccess,
  [GroupTypes.LEAVE_GROUP_FAILURE]: leaveGroupFailure,
  [GroupTypes.REMOVE_MEMBER_SUCCESS]: removeMemberSuccess,
  [GroupTypes.REMOVE_MEMBER_FAILURE]: removeMemberFailure,
  [GroupTypes.ACTIVE_GROUP_SUCCESS]: activeGroupSuccess,
  [GroupTypes.ACTIVE_GROUP_FAILURE]: activeGroupFailure,
  [GroupTypes.ENTRUST_VIDEO_SUCCESS]: entrustVideoSuccess,
  [GroupTypes.ENTRUST_VIDEO_FAILURE]: entrustVideoFailure,
  [GroupTypes.GROUPS_ALL_FETCH_SUCCESS]: fetchGroupAllSuccess,
  [GroupTypes.GROUPS_ALL_FETCH_FAILURE]: fetchGroupAllFailure,
  [GroupTypes.INVITE_MEMBER_REQUEST]: inviteMemberRequest,
  [GroupTypes.INVITE_MEMBER_SUCCESS]: inviteMemberSuccess,
  [GroupTypes.INVITE_MEMBER_FAILURE]: inviteMemberFailure,
  [GroupTypes.INVITE_DETAIL_SUCCESS]: inviteDetailSuccess,
  [GroupTypes.INVITE_DETAIL_FAILURE]: inviteDetailFailure,
  [GroupTypes.GROUPS_WP_FETCH_SUCCESS]: fetchSuccessWithoutPaginate,
  [GroupTypes.GROUPS_WP_FETCH_FAILURE]: fetchFailureWithoutPaginate,
  [GroupTypes.GROUP_MEMBERS_INVITE_FETCH_SUCCESS]: fetchGroupMembersInviteSuccess,
  [GroupTypes.GROUP_MEMBERS_INVITE_FETCH_FAILURE]: fetchGroupMembersInviteFailure
};

export const groupReducer = createReducer(INITIAL_STATE, HANDLERS);
