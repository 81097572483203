import { createReducer } from 'reduxsauce';
import { GetVideoTypes } from './actions';

const requestState = {
  error: null,
  videos: [],
  videosByUser: [],
  total: 0,
  loading: false,
  createStatus: { loading: false, error: null }
};

export const INITIAL_STATE = {
  ...requestState
};

const getMyVideos = (state = INITIAL_STATE, actions) => ({
  ...state,
  videos: actions.videos
});

const getVideosByUserRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});

const getVideosByUserSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  videosByUser: actions.data?.result,
  total: actions.data?.total,
  loading: false
});
const getVideosByUserFailure = (state = INITIAL_STATE, error) => ({
  ...state,
  error,
  loading: false
});

const searchRequest = (state = INITIAL_STATE, actions) => ({
  ...state,
  loading: true,
  searchTerm: actions.searchTerm
});

const searchSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  videos: actions.videos,
  loading: false
});
const searchFailure = (state = INITIAL_STATE, error) => ({
  ...state,
  error,
  loading: false
});

const deleteVideoRequest = (state = INITIAL_STATE, actions) => ({
  ...state,
  videos: actions.newListVideo,
  loading: true
});

const deleteVideoSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false
});

const deleteVideoFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  loading: false,
  error: actions.error
});
const forceDeleteVideoRequest = (state = INITIAL_STATE, actions) => ({
  ...state,
  videos: actions.newListVideo,
  loading: true
});

const forceDeleteVideoSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false
});

const forceDeleteVideoFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  loading: false,
  videos: actions.videos,
  error: actions.error
});

const editTitleVideoRequest = (state = INITIAL_STATE, actions) => ({
  ...state,
  loading: true,
  videos: actions.videos
});
const editTitleVideoSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  loading: false,
  videos: actions.videos
});
const editTitleVideoFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  loading: false,
  videos: actions.videos,
  error: actions.error
});

const restoreVideoRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});
const restoreVideoSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  loading: false,
  videos: actions.videos
});
const restoreVideoFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  loading: false,
  videos: actions.videos,
  error: actions.error
});

const createNewBlankVideoRequest = (state = INITIAL_STATE) => ({
  ...state,
  createStatus: { loading: true }
});
const createNewBlankVideoSuccess = (state = INITIAL_STATE) => ({
  ...state,
  createStatus: { loading: false }
});
const createNewBlankVideoFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  createStatus: { loading: false, error: actions.error }
});
const buildSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  videos: state.videos.map((video) => (video._id === actions.video._id ? actions.video : video))
});
const updateVideo = (state = INITIAL_STATE, actions) => ({
  ...state,
  videos: state.videos.map((video) => (video._id === actions.videoID ? { ...video, ...actions.data } : video))
});

const getVideosOrg = (state = INITIAL_STATE, actions) => ({
  ...state,
  videosOrg: actions.videos
});
const cloneVideoRequest = (state = INITIAL_STATE) => ({
  ...state
});
const cloneVideoSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  videos: actions.videos
});
const cloneVideoFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  error: actions.error,
  videos: actions.videos
});

export const HANDLERS = {
  [GetVideoTypes.GET_VIDEOS]: getMyVideos,

  [GetVideoTypes.GET_VIDEOS_BY_USER_REQUEST]: getVideosByUserRequest,
  [GetVideoTypes.GET_VIDEOS_BY_USER_SUCCESS]: getVideosByUserSuccess,
  [GetVideoTypes.GET_VIDEOS_BY_USER_FAILURE]: getVideosByUserFailure,

  [GetVideoTypes.GET_VIDEOS_SEARCH_REQUEST]: searchRequest,
  [GetVideoTypes.GET_VIDEOS_SEARCH_SUCCESS]: searchSuccess,
  [GetVideoTypes.GET_VIDEOS_SEARCH_FAILURE]: searchFailure,

  [GetVideoTypes.DELETE_VIDEO_REQUEST]: deleteVideoRequest,
  [GetVideoTypes.DELETE_VIDEO_SUCCESS]: deleteVideoSuccess,
  [GetVideoTypes.DELETE_VIDEO_FAILURE]: deleteVideoFailure,

  [GetVideoTypes.FORCE_DELETE_VIDEO_REQUEST]: forceDeleteVideoRequest,
  [GetVideoTypes.FORCE_DELETE_VIDEO_SUCCESS]: forceDeleteVideoSuccess,
  [GetVideoTypes.FORCE_DELETE_VIDEO_FAILURE]: forceDeleteVideoFailure,

  [GetVideoTypes.EDIT_TITLE_VIDEO_REQUEST]: editTitleVideoRequest,
  [GetVideoTypes.EDIT_TITLE_VIDEO_SUCCESS]: editTitleVideoSuccess,
  [GetVideoTypes.EDIT_TITLE_VIDEO_FAILURE]: editTitleVideoFailure,

  [GetVideoTypes.RESTORE_VIDEO_REQUEST]: restoreVideoRequest,
  [GetVideoTypes.RESTORE_VIDEO_SUCCESS]: restoreVideoSuccess,
  [GetVideoTypes.RESTORE_VIDEO_FAILURE]: restoreVideoFailure,

  [GetVideoTypes.CREATE_NEW_BLANK_VIDEO_REQUEST]: createNewBlankVideoRequest,
  [GetVideoTypes.CREATE_NEW_BLANK_VIDEO_SUCCESS]: createNewBlankVideoSuccess,
  [GetVideoTypes.CREATE_NEW_BLANK_VIDEO_FAILURE]: createNewBlankVideoFailure,

  [GetVideoTypes.HOME_VIDEO_BUILD_SUCCESS]: buildSuccess,
  [GetVideoTypes.HOME_VIDEO_UPDATE_VIDEO]: updateVideo,
  [GetVideoTypes.GET_VIDEOS_ORG]: getVideosOrg,

  [GetVideoTypes.CLONE_VIDEO_REQUEST]: cloneVideoRequest,
  [GetVideoTypes.CLONE_VIDEO_SUCCESS]: cloneVideoSuccess,
  [GetVideoTypes.CLONE_VIDEO_FAILURE]: cloneVideoFailure
};

export const homeVideosReducer = createReducer(INITIAL_STATE, HANDLERS);
