/* eslint-disable camelcase */
import { createTypes } from 'reduxsauce';

export const AppTypes = createTypes(`
  LANGUAGE_REQUEST
  LANGUAGE_SUCCESS
  LANGUAGE_FAILURE

  LOGIN
  LOGOUT

  MODAL_SHOW
  MODAL_CLOSE
  MODAL_REFRESH
  
  UPDATE_USER
`);

export const languageSuccess = (user) => ({ type: AppTypes.LANGUAGE_SUCCESS, user });

export const languageFailure = (error) => ({
  error,
  type: AppTypes.LANGUAGE_FAILURE
});

export const languageRequest =
  ({ language }) =>
  (dispatch) =>
    dispatch({ type: AppTypes.LANGUAGE_REQUEST, language });

export const login =
  ({ access_token, user }) =>
  (dispatch) => {
    dispatch({ type: AppTypes.LOGIN, user });
    localStorage.setItem('access_token', access_token);
  };

export const logout = () => (dispatch) => {
  dispatch({ type: AppTypes.LOGOUT });
  localStorage.removeItem('access_token');
};

export const modalShow = (modal, data) => ({
  type: AppTypes.MODAL_SHOW,
  modal,
  data
});

export const modalClose = (modal) => ({ type: AppTypes.MODAL_CLOSE, modal });

export const modalRefresh = (modal) => ({
  type: AppTypes.MODAL_REFRESH,
  modal
});
