import { createReducer } from 'reduxsauce';
import { LoginTypes } from './actions';

const requestState = {
  error: null,
  value: false,
  loading: false,
  timeTrigger: 0,
  updateUserError: null,
  changePasswordTrigger: 0,
  changePasswordError: null
};

export const INITIAL_STATE = {
  ...requestState
};

const loginRequest = () => ({
  ...INITIAL_STATE,
  loading: true
});

const loginSuccess = (state = INITIAL_STATE, { user }) => ({
  ...state,
  value: user,
  loading: false
});

const loginFailure = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error,
  loading: false
});

const updateUserSuccess = (state = INITIAL_STATE) => ({
  ...state,
  timeTrigger: new Date().getTime(),
  updateUserError: null
});

const updateUserFailure = (state = INITIAL_STATE, { error }) => ({
  ...state,
  updateUserError: error
});

const changePasswordSuccess = (state = INITIAL_STATE) => ({
  ...state,
  changePasswordTrigger: new Date().getTime(),
  changePasswordError: null
});

const changePasswordFailure = (state = INITIAL_STATE, { error }) => ({
  ...state,
  changePasswordError: error
});

export const HANDLERS = {
  [LoginTypes.LOGIN_REQUEST]: loginRequest,
  [LoginTypes.LOGIN_SUCCESS]: loginSuccess,
  [LoginTypes.LOGIN_FAILURE]: loginFailure,
  [LoginTypes.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [LoginTypes.UPDATE_USER_FAILURE]: updateUserFailure,
  [LoginTypes.CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
  [LoginTypes.CHANGE_PASSWORD_FAILURE]: changePasswordFailure
};

export const loginReducer = createReducer(INITIAL_STATE, HANDLERS);
