import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'GoogleSans-Medium'
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1440,
      xl: 1920
    },
    step: 0
  },
  palette: {
    custom: {
      contrastText: '#2E6BB4',
      dark: '#98cefa',
      light: '#E4F3FF',
      main: '#2E6BB4'
    },
    paginate: {
      contrastText: '#fff',
      dark: '#00905f',
      light: '#33d7a0',
      main: '#01CE89'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box'
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%'
        },
        body: {
          height: '100%'
        },
        '#root': {
          height: '100%'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    }
  }
});

export default theme;
