export const ENDPOINTS = {
  video: (id) => `/videos/${id}`,
  scenes: (id) => `/videos/${id}/scenes`,
  scene: (videoID, sceneID) => `/videos/${videoID}/scenes/${sceneID}`,
  duplicateScene: (videoID, sceneID) => `/videos/${videoID}/scenes/${sceneID}/duplicate`,
  media: (mediaID) => `/media/${mediaID}`,
  buildVideo: (id) => `/videos/${id}/produce`,
  importSlide: (id) => `/videos/${id}/import-file`,
  resize: (videoID, sceneID) => `/videos/${videoID}/scenes/${sceneID}/resize`,
  logo: (videoID) => `/videos/${videoID}/logo`,
  deleteSound: (soundID) => `/media/${soundID}`,
  addSoundToVideo: (videoID) => `/videos/${videoID}/sound`,
  changeVolumeVideoAndScene: (videoID) => `/videos/${videoID}/volume`,
  uploadSpeech: (videoID, sceneID) => `/videos/${videoID}/scenes/${sceneID}/voice`,
  applySceneTemplate: (videoID, sceneID, sceneTemplateID) =>
    `videos/${videoID}/scenes/${sceneID}/templates/${sceneTemplateID}`,
  applySceneTemplateForAll: (videoID, templateID) => `/videos/${videoID}/template/${templateID}`,
  arrangeScene: (videoID) => `/videos/${videoID}/arrange-scene`,
  deleteTemplate: (id) => `/templates/${id}`
};
