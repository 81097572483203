import { createReducer } from 'reduxsauce';
import { cloneDeep } from 'lodash';
import { EditVideoTypes } from './actions';

const requestState = {
  video: null,
  loading: false,
  myTemplates: [],
  timeUpdateSceneSuccess: 0,
  error: null,
  selectingStatus: {
    sceneID: '',
    folderID: '',
    loading: false,
    error: null
  },
  mediaUploadStatus: {
    files: [],
    error: null,
    loading: false
  },
  createSceneStatus: {
    loading: false,
    error: null
  },
  importSlideStatus: {
    loading: false,
    error: null
  },
  uploadAudioStatus: {
    loading: false,
    error: null
  },
  deleteAudioStatus: {
    loading: false,
    error: null
  },
  buildStatus: {
    loading: false,
    url: ''
  },
  sceneCheck: {
    listProgress: []
  }
};

export const INITIAL_STATE = {
  ...requestState
};

const resetRequest = () => INITIAL_STATE;

const fetchRequest = (state = INITIAL_STATE) => ({
  ...state,
  // loading: true,
  error: null
});

const fetchSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: actions.video,
  tags: actions.tags,
  selectingStatus: {
    ...state.selectingStatus,
    sceneID: actions.video.scenes.length ? actions.video.scenes[0]._id : '',
    folderID: actions.video.folders.length ? actions.video.folders[0]._id : ''
  },
  // loading: false,
  error: null
});

const fetchFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  // loading: false,
  error: actions.error
});

const hotUpdateRequest = (state = INITIAL_STATE, actions) => ({
  ...state,
  changeFormatLoading: actions.changeFormatLoading,
  video: {
    ...state.video,
    ...actions.data,
    ...actions.data.upload_first_time
  },
  error: null
});

const hotUpdateSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: actions.isChangingFormat
    ? {
        ...state.video,
        ...actions.video
      }
    : state.video,
  changeFormatLoading: false,
  error: null
});

const hotUpdateFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  changeFormatLoading: false,
  video: actions.video,
  error: actions.error
});

const uploadMediaRequest = (state = INITIAL_STATE, actions) => ({
  ...state,
  mediaUploadStatus: {
    error: null,
    loading: true,
    files: actions.files,
    folderID: actions.folderID
  }
});

const uploadMediaSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    folders: actions.isInFolder
      ? state.video.folders.map((folder) => (folder._id === actions.data._id ? actions.data : folder))
      : state.video.folders,
    media: !actions.isInFolder ? actions.data : state.video.media
  },
  mediaUploadStatus: {
    error: null,
    loading: false,
    files: null
  }
});

const uploadMediaFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  mediaUploadStatus: {
    error: actions.error,
    loading: false,
    files: null
  }
});

const createSceneRequest = (state = INITIAL_STATE, actions) => ({
  ...state,
  createSceneStatus: {
    loading: true,
    error: null
  },
  video: {
    ...state.video,
    scenes: actions.scenes
  }
});

const createSceneSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    scenes: state.video.scenes.map((scene) => (scene._id === '~new' ? actions.scene : scene))
  },
  createSceneStatus: {
    loading: false,
    error: null
  }
});

const createSceneFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    scenes: actions.scenes
  },
  createSceneStatus: {
    loading: false,
    error: actions.error
  }
});

const deleteSceneRequest = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    scenes: state.video.scenes.filter((scene) => scene._id !== actions.sceneID)
  },
  selectingStatus: {
    ...state.selectingStatus,
    sceneID:
      // eslint-disable-next-line no-nested-ternary
      actions.sceneID === state.selectingStatus.sceneID
        ? state.video.scenes.length
          ? state.video.scenes[0]._id
          : ''
        : state.selectingStatus.sceneID
  }
});

const deleteSceneSuccess = (state = INITIAL_STATE) => ({
  ...state
  // video: {
  //   ...state.video,
  //   scenes: actions.scenes
  // }
});

const deleteSceneFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    scenes: actions.scenes
  }
});

const reorderSceneRequest = (state = INITIAL_STATE) => ({
  ...state
  // video: {
  //   ...state.video,
  //   scenes: actions.scenes
  // }
});

const reorderSceneSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    scenes: actions.scenes
  }
});

const reorderSceneFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    scenes: actions.scenes
  }
});

const setSelectedSceneID = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video
  },
  selectingStatus: {
    ...state.selectingStatus,
    sceneID: actions.id
  }
});
const setSelectedFolderID = (state = INITIAL_STATE, actions) => ({
  ...state,
  selectingStatus: {
    ...state.selectingStatus,
    folderID: actions.id
  }
});

const changeMediaRequest = (state = INITIAL_STATE) => ({
  ...state,
  selectingStatus: {
    ...state.selectingStatus,
    loading: true
  }
});

const changeMediaSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    scenes: state.video.scenes.map((scene) => (scene._id === actions.scene._id ? actions.scene : scene))
  },
  selectingStatus: {
    ...state.selectingStatus,
    loading: false
  }
});

const changeMediaFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  selectingStatus: {
    ...state.selectingStatus,
    loading: false,
    error: actions.error
  }
});

const setVideoState = (state = INITIAL_STATE, actions) => ({
  ...state,
  videoState: {
    ...state.videoState,
    ...actions.state
  }
});

const duplicateSceneRequest = (state = INITIAL_STATE, actions) => ({
  ...state,
  duplicateSceneStatus: {
    loading: true,
    error: null
  },
  video: {
    ...state.video,
    scenes: actions.scenes
  }
});

const duplicateSceneSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    scenes: state.video.scenes.map((scene) => (scene._id === '~duplicate' ? actions.scene : scene))
  },
  duplicateSceneStatus: {
    loading: false,
    error: null
  }
});

const duplicateSceneFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    scenes: actions.scenes
  },
  duplicateSceneStatus: {
    loading: false,
    error: actions.error
  }
});

const hotUpdateSceneRequest = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    scenes: state.video.scenes.map((scene) => (scene._id === actions.scene._id ? actions.scene : scene))
  }
});

const hotUpdateSceneSuccess = (state = INITIAL_STATE) => ({
  ...state,
  video: {
    ...state.video
  }
});

const hotUpdateSceneFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    scenes: state.video.scenes.map((scene) => (scene._id === actions.scene._id ? actions.scene : scene)),
    folders: state.video.folders.map((folder) => (folder._id === actions.folder._id ? actions.folder : folder))
  }
});

const deleteMediaRequest = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    media: actions.media,
    folders: actions.folders
  }
});

const deleteMediaSuccess = (state = INITIAL_STATE) => ({
  ...state
});

const deleteMediaFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    media: actions.media
  }
});

const importSlideRequest = (state = INITIAL_STATE) => ({
  ...state,
  importSlideStatus: {
    loading: true
  }
});

const importSlideSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    scenes: actions.scenes
  },
  importSlideStatus: {
    loading: false,
    error: null
  }
});

const importSlideFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    scenes: actions.listScene
  },
  importSlideStatus: {
    loading: false,
    error: actions.error
  }
});

const buildRequest = (state = INITIAL_STATE) => ({
  ...state,
  buildStatus: {
    ...state.buildStatus,
    loading: true,
    error: null
  }
});

const buildSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  buildStatus: {
    ...state.buildStatus,
    loading: false,
    url: actions.url
  },
  video: {
    ...state.video,
    produce_flg: false,
    embed_url: actions.url
  }
});

const buildFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  buildStatus: {
    ...state.buildStatus,
    loading: false,
    error: actions.error
  }
});

const resizeSceneRequest = (state = INITIAL_STATE) => ({
  ...state,
  cropAndTrimStatus: {
    loading: true
  }
});

const resizeSceneSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    scenes: state.video.scenes.map((scene) => (scene._id === actions.scene._id ? actions.scene : scene))
  },
  cropAndTrimStatus: {
    loading: false
  }
});

const resizeSceneFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  cropAndTrimStatus: {
    loading: false,
    error: actions.error
  }
});
const uploadAudioRequest = (state = INITIAL_STATE, actions) => ({
  ...state,
  uploadSoundStatus: {
    error: null,
    loading: true,
    files: actions.files
  }
});

const uploadAudioSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    sounds: actions.sound
  },
  uploadSoundStatus: {
    error: null,
    loading: false,
    files: null
  }
});

const changeLogoTemporary = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    logo: {
      ...state.video.logo,
      ...actions.props
    }
  }
});

const hotUpdateLogoRequest = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    logo: actions.logo
  }
});

const hotUpdateLogoSuccess = (state = INITIAL_STATE) => ({
  ...state
});

const hotUpdateLogoFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    logo: actions.logo
  }
});

const uploadLogoRequest = (state = INITIAL_STATE) => ({
  ...state
});

const uploadLogoSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    logo: actions.logo
  }
});

const uploadLogoFailure = (state = INITIAL_STATE) => ({
  ...state
});

const uploadAudioFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  uploadAudioStatus: {
    error: actions.error,
    loading: false,
    files: null
  }
});
const deleteAudioRequest = (state = INITIAL_STATE) => ({
  ...state,
  video: {
    ...state.video,
    sounds: [...state.video.sounds]
  }
});

const deleteAudioSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    sounds: actions.sounds
  }
});

const deleteAudioFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    sounds: [actions.sounds]
  }
});

const addSoundToVideoRequest = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    background_sound:
      actions.soundID === state.video.background_sound?._id
        ? null
        : {
            _id: actions.soundID
          }
  }
});

const addSoundToVideoSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    background_sound: actions.backgroundSound,
    sounds: state.video.sounds.map((sound) => {
      if (sound._id === actions.backgroundSound?._id) {
        return {
          ...actions.backgroundSound,
          is_active: true
        };
      }
      return {
        ...sound,
        is_active: false
      };
    })
  }
});

const addSoundToVideoFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    background_sound: actions.backgroundSoundList
  }
});

const addFolder = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    folders: [...state.video.folders, actions.folder]
  }
});

const deleteFolder = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    folders: state.video.folders.filter((folder) => folder._id !== actions.id)
  }
});
const changeVolumeVideoAndSceneRequest = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    background_volume: actions.videoVolume,
    scene_volume: actions.sceneVolume
  }
});
const changeVolumeVideoAndSceneSuccess = (state = INITIAL_STATE) => ({
  ...state,
  timeUpdateSceneSuccess: new Date().getTime()
});
const changeVolumeVideoAndSceneFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    background_volume: actions.videoVolume,
    scene_volume: actions.sceneVolume
  }
});
const updateAudioInfo = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    scenes: state.video.scenes.map((scene) =>
      scene._id === actions.id
        ? {
            ...scene,
            speech: {
              ...scene.speech,
              url: actions.url,
              voice: actions.voice,
              speed: actions.speed,
              duration: actions.duration
            }
          }
        : scene
    )
  }
});
const uploadSpeechRequest = (state = INITIAL_STATE) => ({
  ...state
});
const uploadSpeechSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    scenes: state.video.scenes.map((scene) =>
      scene._id === actions.id
        ? {
            ...scene,
            speech: {
              ...scene.speech,
              url: actions.speechUrl
            }
          }
        : scene
    )
  }
});
const uploadSpeechFailure = (state = INITIAL_STATE) => ({
  ...state
});

const applySceneTemplateRequest = (state = INITIAL_STATE) => ({
  ...state,
  selectingStatus: {
    ...state.selectingStatus,
    loading: true
  }
});

const applySceneTemplateSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    scenes: state.video.scenes.map((scene) => (scene._id === actions.scene._id ? actions.scene : scene))
  },
  selectingStatus: {
    ...state.selectingStatus,
    loading: false
  }
});

const applySceneTemplateFailure = (state = INITIAL_STATE) => ({
  ...state,
  selectingStatus: {
    ...state.selectingStatus,
    loading: false
  }
});

const updateSceneFEOnly = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    scenes: state.video.scenes.map((scene) => (scene._id === actions.sceneID ? { ...scene, ...actions.data } : scene))
  }
});

const setLoadingScene = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    scenes: state.video.scenes.map((scene) =>
      scene._id === actions.id ? { ...scene, loadingScene: actions.loading } : scene
    )
  }
});

const applySceneTemplateForAllRequest = (state = INITIAL_STATE) => ({
  ...state
});

const applySceneTemplateForAllSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    scenes: actions.scenes
  }
});
const applySceneTemplateForAllFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    scenes: actions.listScenes
  },
  error: actions.error
});

const updateMediaFolderSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    folders: state.video.folders.map((folder) => (folder._id === actions.folder._id ? actions.folder : folder))
  }
});

const updateMediaFolderRequest = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    folders: state.video.folders.map((folder) => (folder._id === actions.id ? { ...folder, loading: true } : folder))
  }
});

const updateMediaFolderFailure = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    folders: state.video.folders.map((folder) => (folder._id === actions.id ? { ...folder, loading: false } : folder))
  }
});

const deleteLogo = (state = INITIAL_STATE) => ({
  ...state,
  video: {
    ...state.video,
    logo: null
  }
});
const deleteTemplateRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});
const deleteTemplateSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  loading: false,
  myTemplates: actions.templates
});
const deleteTemplateFailure = (state = INITIAL_STATE, error, actions) => ({
  ...state,
  myTemplates: actions.listTemplate,
  error
});

const setTemplate = (state = INITIAL_STATE, actions) => ({
  ...state,
  myTemplates: actions.templates
});

const updateVoiceAndSpeed = (state = INITIAL_STATE, actions) => ({
  ...state,
  video: {
    ...state.video,
    speed: actions.speed,
    voice: actions.voice
  }
});

const setTimeUpdateScene = (state = INITIAL_STATE) => ({
  ...state,
  timeUpdateSceneSuccess: 0
});

const setSceneCheck = (state = INITIAL_STATE, actions) => {
  if (actions.sceneCheck.singleProgress) {
    const cloneListProgress = cloneDeep(state.sceneCheck.listProgress);
    cloneListProgress.push(actions.sceneCheck.singleProgress);
    return {
      ...state,
      sceneCheck: {
        listProgress: cloneListProgress
      }
    };
  }
  return {
    ...state,
    sceneCheck: actions.sceneCheck
  };
};

export const HANDLERS = {
  [EditVideoTypes.EDIT_VIDEO_RESET]: resetRequest,

  [EditVideoTypes.EDIT_VIDEO_FETCH_REQUEST]: fetchRequest,
  [EditVideoTypes.EDIT_VIDEO_FETCH_FAILURE]: fetchFailure,
  [EditVideoTypes.EDIT_VIDEO_FETCH_SUCCESS]: fetchSuccess,

  [EditVideoTypes.EDIT_VIDEO_HOT_UPDATE_REQUEST]: hotUpdateRequest,
  [EditVideoTypes.EDIT_VIDEO_HOT_UPDATE_FAILURE]: hotUpdateFailure,
  [EditVideoTypes.EDIT_VIDEO_HOT_UPDATE_SUCCESS]: hotUpdateSuccess,

  [EditVideoTypes.EDIT_VIDEO_UPLOAD_MEDIA_REQUEST]: uploadMediaRequest,
  [EditVideoTypes.EDIT_VIDEO_UPLOAD_MEDIA_FAILURE]: uploadMediaFailure,
  [EditVideoTypes.EDIT_VIDEO_UPLOAD_MEDIA_SUCCESS]: uploadMediaSuccess,

  [EditVideoTypes.EDIT_VIDEO_CREATE_SCENE_REQUEST]: createSceneRequest,
  [EditVideoTypes.EDIT_VIDEO_CREATE_SCENE_FAILURE]: createSceneFailure,
  [EditVideoTypes.EDIT_VIDEO_CREATE_SCENE_SUCCESS]: createSceneSuccess,

  [EditVideoTypes.EDIT_VIDEO_DELETE_SCENE_REQUEST]: deleteSceneRequest,
  [EditVideoTypes.EDIT_VIDEO_DELETE_SCENE_FAILURE]: deleteSceneFailure,
  [EditVideoTypes.EDIT_VIDEO_DELETE_SCENE_SUCCESS]: deleteSceneSuccess,

  [EditVideoTypes.EDIT_VIDEO_REORDER_SCENE_REQUEST]: reorderSceneRequest,
  [EditVideoTypes.EDIT_VIDEO_REORDER_SCENE_FAILURE]: reorderSceneFailure,
  [EditVideoTypes.EDIT_VIDEO_REORDER_SCENE_SUCCESS]: reorderSceneSuccess,

  [EditVideoTypes.EDIT_VIDEO_SET_SELECTED_SCENE_ID]: setSelectedSceneID,
  [EditVideoTypes.EDIT_VIDEO_SET_SELECTED_FOLDER_ID]: setSelectedFolderID,

  [EditVideoTypes.EDIT_VIDEO_CHANGE_MEDIA_REQUEST]: changeMediaRequest,
  [EditVideoTypes.EDIT_VIDEO_CHANGE_MEDIA_FAILURE]: changeMediaFailure,
  [EditVideoTypes.EDIT_VIDEO_CHANGE_MEDIA_SUCCESS]: changeMediaSuccess,

  [EditVideoTypes.EDIT_VIDEO_SET_VIDEO_STATE]: setVideoState,

  [EditVideoTypes.EDIT_VIDEO_DUPLICATE_SCENE_REQUEST]: duplicateSceneRequest,
  [EditVideoTypes.EDIT_VIDEO_DUPLICATE_SCENE_FAILURE]: duplicateSceneFailure,
  [EditVideoTypes.EDIT_VIDEO_DUPLICATE_SCENE_SUCCESS]: duplicateSceneSuccess,

  [EditVideoTypes.EDIT_VIDEO_HOT_UPDATE_SCENE_REQUEST]: hotUpdateSceneRequest,
  [EditVideoTypes.EDIT_VIDEO_HOT_UPDATE_SCENE_FAILURE]: hotUpdateSceneFailure,
  [EditVideoTypes.EDIT_VIDEO_HOT_UPDATE_SCENE_SUCCESS]: hotUpdateSceneSuccess,

  [EditVideoTypes.EDIT_VIDEO_DELETE_MEDIA_REQUEST]: deleteMediaRequest,
  [EditVideoTypes.EDIT_VIDEO_DELETE_MEDIA_FAILURE]: deleteMediaFailure,
  [EditVideoTypes.EDIT_VIDEO_DELETE_MEDIA_SUCCESS]: deleteMediaSuccess,

  [EditVideoTypes.EDIT_VIDEO_BUILD_REQUEST]: buildRequest,
  [EditVideoTypes.EDIT_VIDEO_BUILD_FAILURE]: buildFailure,
  [EditVideoTypes.EDIT_VIDEO_BUILD_SUCCESS]: buildSuccess,

  [EditVideoTypes.EDIT_VIDEO_IMPORT_SLIDE_REQUEST]: importSlideRequest,
  [EditVideoTypes.EDIT_VIDEO_IMPORT_SLIDE_FAILURE]: importSlideFailure,
  [EditVideoTypes.EDIT_VIDEO_IMPORT_SLIDE_SUCCESS]: importSlideSuccess,

  [EditVideoTypes.EDIT_VIDEO_RESIZE_SCENE_REQUEST]: resizeSceneRequest,
  [EditVideoTypes.EDIT_VIDEO_RESIZE_SCENE_FAILURE]: resizeSceneFailure,
  [EditVideoTypes.EDIT_VIDEO_RESIZE_SCENE_SUCCESS]: resizeSceneSuccess,

  [EditVideoTypes.EDIT_VIDEO_CHANGE_LOGO_TEMPORARY]: changeLogoTemporary,
  [EditVideoTypes.EDIT_VIDEO_HOT_UPDATE_LOGO_REQUEST]: hotUpdateLogoRequest,
  [EditVideoTypes.EDIT_VIDEO_HOT_UPDATE_LOGO_FAILURE]: hotUpdateLogoFailure,
  [EditVideoTypes.EDIT_VIDEO_HOT_UPDATE_LOGO_SUCCESS]: hotUpdateLogoSuccess,

  [EditVideoTypes.EDIT_VIDEO_UPLOAD_LOGO_REQUEST]: uploadLogoRequest,
  [EditVideoTypes.EDIT_VIDEO_UPLOAD_LOGO_FAILURE]: uploadLogoFailure,
  [EditVideoTypes.EDIT_VIDEO_UPLOAD_LOGO_SUCCESS]: uploadLogoSuccess,
  [EditVideoTypes.EDIT_VIDEO_UPLOAD_AUDIO_REQUEST]: uploadAudioRequest,
  [EditVideoTypes.EDIT_VIDEO_UPLOAD_AUDIO_FAILURE]: uploadAudioFailure,
  [EditVideoTypes.EDIT_VIDEO_UPLOAD_AUDIO_SUCCESS]: uploadAudioSuccess,

  [EditVideoTypes.EDIT_VIDEO_DELETE_AUDIO_REQUEST]: deleteAudioRequest,
  [EditVideoTypes.EDIT_VIDEO_DELETE_AUDIO_FAILURE]: deleteAudioFailure,
  [EditVideoTypes.EDIT_VIDEO_DELETE_AUDIO_SUCCESS]: deleteAudioSuccess,

  [EditVideoTypes.EDIT_VIDEO_ADD_SOUND_TO_VIDEO_REQUEST]: addSoundToVideoRequest,
  [EditVideoTypes.EDIT_VIDEO_ADD_SOUND_TO_VIDEO_FAILURE]: addSoundToVideoFailure,
  [EditVideoTypes.EDIT_VIDEO_ADD_SOUND_TO_VIDEO_SUCCESS]: addSoundToVideoSuccess,
  [EditVideoTypes.EDIT_VIDEO_ADD_NEW_FOLDER]: addFolder,
  [EditVideoTypes.EDIT_VIDEO_DELETE_FOLDER]: deleteFolder,

  [EditVideoTypes.EDIT_VIDEO_CHANGE_VOLUME_VIDEO_AND_SCENE_REQUEST]: changeVolumeVideoAndSceneRequest,
  [EditVideoTypes.EDIT_VIDEO_CHANGE_VOLUME_VIDEO_AND_SCENE_FAILURE]: changeVolumeVideoAndSceneFailure,
  [EditVideoTypes.EDIT_VIDEO_CHANGE_VOLUME_VIDEO_AND_SCENE_SUCCESS]: changeVolumeVideoAndSceneSuccess,
  [EditVideoTypes.EDIT_VIDEO_UPDATE_URL_AUDIO]: updateAudioInfo,

  [EditVideoTypes.EDIT_VIDEO_UPLOAD_SPEECH_REQUEST]: uploadSpeechRequest,
  [EditVideoTypes.EDIT_VIDEO_UPLOAD_SPEECH_SUCCESS]: uploadSpeechSuccess,
  [EditVideoTypes.EDIT_VIDEO_UPLOAD_SPEECH_FAILURE]: uploadSpeechFailure,

  [EditVideoTypes.EDIT_VIDEO_APPLY_SCENE_TEMPLATE_REQUEST]: applySceneTemplateRequest,
  [EditVideoTypes.EDIT_VIDEO_APPLY_SCENE_TEMPLATE_SUCCESS]: applySceneTemplateSuccess,
  [EditVideoTypes.EDIT_VIDEO_APPLY_SCENE_TEMPLATE_FAILURE]: applySceneTemplateFailure,

  [EditVideoTypes.EDIT_VIDEO_UPDATE_SCENE_FE_ONLY]: updateSceneFEOnly,

  [EditVideoTypes.EDIT_VIDEO_SET_LOADING_SCENE]: setLoadingScene,

  [EditVideoTypes.EDIT_VIDEO_APPLY_SCENE_TEMPLATE_FOR_ALL_REQUEST]: applySceneTemplateForAllRequest,
  [EditVideoTypes.EDIT_VIDEO_APPLY_SCENE_TEMPLATE_FOR_ALL_SUCCESS]: applySceneTemplateForAllSuccess,
  [EditVideoTypes.EDIT_VIDEO_APPLY_SCENE_TEMPLATE_FOR_ALL_FAILURE]: applySceneTemplateForAllFailure,

  [EditVideoTypes.EDIT_VIDEO_UPDATE_MEDIA_FOLDER_SUCCESS]: updateMediaFolderSuccess,
  [EditVideoTypes.EDIT_VIDEO_UPDATE_MEDIA_FOLDER_FAILURE]: updateMediaFolderFailure,
  [EditVideoTypes.EDIT_VIDEO_UPDATE_MEDIA_FOLDER_REQUEST]: updateMediaFolderRequest,
  [EditVideoTypes.EDIT_VIDEO_DELETE_LOGO]: deleteLogo,

  [EditVideoTypes.EDIT_VIDEO_DELETE_TEMPLATE_SUCCESS]: deleteTemplateSuccess,
  [EditVideoTypes.EDIT_VIDEO_DELETE_TEMPLATE_FAILURE]: deleteTemplateFailure,
  [EditVideoTypes.EDIT_VIDEO_DELETE_TEMPLATE_REQUEST]: deleteTemplateRequest,

  [EditVideoTypes.EDIT_VIDEO_SET_TEMPLATE]: setTemplate,

  [EditVideoTypes.EDIT_VIDEO_UPDATE_VOICE_AND_SPEED]: updateVoiceAndSpeed,

  [EditVideoTypes.EDIT_VIDEO_SET_TIME_UPDATE_SCENE]: setTimeUpdateScene,
  [EditVideoTypes.EDIT_VIDEO_SET_SCENE_CHECK]: setSceneCheck
};

export const editVideoReducer = createReducer(INITIAL_STATE, HANDLERS);
