import { createReducer } from 'reduxsauce';
import { RegisterTypes } from './actions';

const requestState = {
  error: null,
  value: null,
  loading: true,
  timeTrigger: 0
};

export const INITIAL_STATE = {
  ...requestState
};

const registerSuccess = (state = INITIAL_STATE, { user }) => ({
  ...state,
  value: user,
  loading: false,
  timeTrigger: new Date().getTime()
});

const registerFailure = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error,
  loading: false
});

export const HANDLERS = {
  [RegisterTypes.REGISTER_SUCCESS]: registerSuccess,
  [RegisterTypes.REGISTER_FAILURE]: registerFailure
};

export const registerReducer = createReducer(INITIAL_STATE, HANDLERS);
