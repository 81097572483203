import { createTypes } from 'reduxsauce';
import { ENDPOINTS } from './config';
import axiosClient from '../../utils/axiosClient2';

export const PackageTypes = createTypes(`
  PACKAGES_FETCH_SUCCESS
  PACKAGES_FETCH_FAILURE
`);

export const fetchSuccess = (response) => ({ type: PackageTypes.PACKAGES_FETCH_SUCCESS, data: response });
export const fetchFailure = (error) => ({ type: PackageTypes.PACKAGES_FETCH_FAILURE, error });
export const fetchPackages = (params) => async (dispatch) => {
  try {
    const response = await axiosClient.get(ENDPOINTS.packages(), { params });
    dispatch(fetchSuccess(response.data));
  } catch (error) {
    dispatch(fetchFailure(error));
  }
};
