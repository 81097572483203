import { setPusherClient } from 'react-pusher';
import Pusher from 'pusher-js';

const pusherClient = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
  cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
  channelAuthorization: {
    endpoint: `${process.env.REACT_APP_BASE_URL}/broadcasting/auth`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`
    }
  }
});

setPusherClient(pusherClient);
